import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactUs from "../components/contactus";


const linkStyle = {
    textDecoration: "none",
    color: "grey",
    fontWeight: 800,
}

const hrStyle = {
    margin: "50px auto"
}

const tableStyle = {
    borderCollapse: "collapse",
    border: "solid 4px #333"
}

const thtdStyle = {
    border: "solid 2px #333",
    padding: "10px",
    verticalAlign: "middle",
}

const AsteriskOperationPage = () => (
    <Layout>
        <Seo title="Asterisk開発・保守運用"/>

        <div align="center">
            <hr style={hrStyle}/>

            <h1 id="asterisk_operation">Asterisk開発・保守運用</h1>

            <div>
                <p>システム開発、保守運用、不具合調査、設定ファイル解析を承ります。</p>
            </div>

            <hr style={hrStyle}/>

            <div>
                <h2>料金</h2>
                <table style={tableStyle}>
                    <colgroup style={thtdStyle}>
                        <col width="25%"/>
                        <col width="80%"/>
                    </colgroup>
                    <tr style={thtdStyle}>
                        <td style={thtdStyle}><big><strong>月額 50,000円+税 ～</strong></big></td>
                        <td style={thtdStyle}>・<b>Asteriskを利用したシステムの開発</b> : <br/> クラウドPBX、オートコール、IVRシステムなどのシステムを構築します。<br/>
                            ・<b>Asterisk運用</b> : <br/> 端末の追加/削除、設定変更などの運用業務を承ります。<br/>
                            ・<b>テクニカルサポート業務</b> : <br/> ログ、設定、パケットキャプチャを調査し、お問い合わせに対する具体案を提案します。<br/>
                            <br/>
                            サポート形態としては、<br/>
                            <b>・弊社エンジニアを貴社プロダクト開発へ参画</b><br/>
                            <b>・弊社にてシステムを開発し、ご提供</b><br/>
                            <b>・メールやタスク管理ツールを利用し、お問い合わせ毎にサポート</b><br/>
                            の３パターンがございます。<br/>
                            お打ち合わせの上、ご予算とご希望に応じて対応いたします。<br/>
                        </td>
                    </tr>
                </table>
            </div>
            <div>
                <p></p>
            </div>
            <div>
                <h2>開発事例</h2>
                <div className="cardListGrid">
                    <Link to="/cloudpbx" style={linkStyle} rel="noreferrer noopener">
                        <div className="card card-skin">
                            <div className="card__imgframe">
                                <StaticImage src="../images/CloudPBX.jpg" alt={"CloudPBX"}/>
                            </div>
                            <div className="card__textbox">
                                <div className="card__titletext">CloudPBX</div>
                                <div className="card__overviewtext">
                                    インターネットとPC/スマートフォンさえあれば利用できる工事の要らないPBX
                                </div>
                            </div>
                        </div>
                    </Link>

                    <div className="card card-skin">
                        <div className="card__imgframe">
                            <StaticImage src="../images/CRM.jpg" alt={"CRM"}/>
                        </div>
                        <div className="card__textbox">
                            <div className="card__titletext">CRM連携</div>
                            <div className="card__overviewtext">
                                ご利用中のCRMとPBXをシームレスに連携し、業務効率を改善します
                            </div>
                        </div>
                    </div>

                    <div className="card card-skin">
                        <div className="card__imgframe">
                            <StaticImage src="../images/CloudFax.jpg" alt={"CloudFAX"}/>
                        </div>
                        <div className="card__textbox">
                            <div className="card__titletext">CloudFAX</div>
                            <div className="card__overviewtext">
                                iPhone/AndroidアプリでFAX送受信
                            </div>
                        </div>
                    </div>

                    <Link
                        to="/siptrunk_gateway"
                        style={linkStyle}
                        rel="noreferrer noopener"
                    >
                        <div className="card card-skin">
                            <div className="card__imgframe">
                                <StaticImage
                                    src="../images/Gateway.jpg"
                                    alt={"SIP Trunk + Gateway"}
                                />
                            </div>
                            <div className="card__textbox">
                                <div className="card__titletext">Gateway / SIP Trunk</div>
                                <div className="card__overviewtext">
                                    キャリアとエンドユーザーを接続するためのハイパフォーマンスSIPゲートウェイ
                                </div>
                            </div>
                        </div>
                    </Link>

                    <div className="card card-skin">
                        <div className="card__imgframe">
                            <StaticImage src="../images/AutoCall.jpg" alt={"AutoCall"}/>
                        </div>
                        <div className="card__textbox">
                            <div className="card__titletext">AutoCall</div>
                            <div className="card__overviewtext">
                                電話番号リストへの効果的な自動発信システム
                            </div>
                        </div>
                    </div>

                    <div className="card card-skin">
                        <div className="card__imgframe">
                            <StaticImage
                                src="../images/GatewayConnect.jpg"
                                alt={"GatewayConnect"}
                            />
                        </div>
                        <div className="card__textbox">
                            <div className="card__titletext">Gateway接続サービス</div>
                            <div className="card__overviewtext">
                                オフィスに設置されたPBXをインターネットに接続し、利便性を高めます
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <p></p>
            </div>
            <div>
                <h2>技術情報</h2>
                <p>開発・保守運用に役立ちそうな技術情報を掲載します。</p>
                <Link to="/documents/q850codes/" style={linkStyle} rel="noreferrer noopener">
                    <p>Q.850 理由表示情報とSIP Response Codeの対応表</p>
                </Link>
            </div>
            <hr style={hrStyle}/>
            <h2>Contact us</h2>
            <ContactUs/>
        </div>
    </Layout>
)

export default AsteriskOperationPage
